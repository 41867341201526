import Link from "next/link";

export default function Footer() {
  return (
    <div className="grow lg:grow-0 py-6 px-4 lg:py-10 lg:px-0 bg-neutral-100">
      <div className="container w-full h-full mx-auto">
        <div className="h-full flex flex-col lg:flex-row lg:items-center justify-between">
          <div className="grow  flex flex-col flex-wrap lg:flex-row gap-y-6 lg:gap-y-4 lg:gap-x-8 lg:items-center  text-black-3 font-semibold text-sm lg:text-base underline decoration-2 underline-offset-4 decoration-black-3/2  ">
            <Link href="#">
              <a className=" block transition duration-300 hover:text-black-2 hover:decoration-black-2">
                Contact Us
              </a>
            </Link>
            <Link href="#">
              <a className=" block transition duration-300 hover:text-black-2 hover:decoration-black-2">
                User Agreement
              </a>
            </Link>
            <Link href="#">
              <a className=" block transition duration-300 hover:text-black-2 hover:decoration-black-2">
                Privacy Policy
              </a>
            </Link>
            <Link href="#">
              <a className=" block transition duration-300 hover:text-black-2 hover:decoration-black-2">
                Online Tracking Opt Out Guide
              </a>
            </Link>
            <Link href="/locations">
              <a className=" block transition duration-300 hover:text-black-2 hover:decoration-black-2">
                Locations
              </a>
            </Link>
            <Link href="#">
              <a className=" block transition duration-300 hover:text-black-2 hover:decoration-black-2">
                Open Source Licenses
              </a>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
